import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/seo";
import Header from "../components/Header";

export default function DonatePage({ data }) {
  const { markdownRemark } = data;
  const { frontmatter } = markdownRemark;
  return (
    <main className="donate">
      <SEO
        title={`${frontmatter.seotitle ? frontmatter.seotitle : frontmatter.title} | Inland Northwest Technologists`}
        keywords={[`Inland Northwest Technologists`, `application`, `react`]}
        description={frontmatter.seodescription}
      />
      <Header {...frontmatter} noScroll={true}>
        <form id="donate-btn" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          <input type="hidden" name="cmd" value="_donations" />
          <input type="hidden" name="business" value={frontmatter.paypalaccount} />
          <input type="hidden" name="item_name" value="Education and Wisdom for the masses!" />
          <input type="hidden" name="currency_code" value="USD" />
          <button className="btn donate-btn" type="submit">
            {frontmatter.donatebuttontext}
          </button>
          <img
            className="donate-btn-img"
            alt=""
            border="0"
            src="https://www.paypal.com/en_US/i/scr/pixel.gif"
            width="1"
            height="1"
          />
        </form>
      </Header>
    </main>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        subtitle
        banner {
          childImageSharp {
            fluid(maxWidth: 1920, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        paypalaccount
        donatebuttontext
      }
    }
  }
`;
